





























































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteWordstatTask,
  dispatchGetWordstatTasks,
  dispatchKillWordstatTask,
  dispatchRunWordstatTask,
  dispatchStatWordstatTasks,
  dispatchDownloadWordstatTaskResult,
  dispatchDownloadWordstatTaskFile,
} from '@/store/wordstat/actions';
import { readWordstatTasks } from '@/store/wordstat/getters';
import { SITE_PARSER_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { IWordstatTaskStats } from '@/interfaces/wordstat';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class WordstatTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readWordstatTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetWordstatTasks(this.$store);
    await dispatchStatWordstatTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetWordstatTasks(this.$store);
      await dispatchStatWordstatTasks(this.$store);
      }, SITE_PARSER_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteWordstatTask(this.$store, {id: taskId});
    await dispatchGetWordstatTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunWordstatTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillWordstatTask(this.$store, {id: taskId});
  }

  public async downloadWordstatTaskResult(taskId: number, result: string) {
    await dispatchDownloadWordstatTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadWordstatTaskFile(taskFile: string) {
    await dispatchDownloadWordstatTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: IWordstatTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
